import type { Component } from "vue";
import IconDashboard from "~/modules/shared/components/icons/IconDashboard.vue";
import IconHelp from "~/modules/shared/components/icons/IconHelp.vue";
import IconMembership from "~/modules/shared/components/icons/IconMembership.vue";
import IconSettings from "~/modules/shared/components/icons/IconSettings.vue";

export const GENERIC_ERROR_SLUG = "GENERIC_ERROR";

export const dashboardNavigationItems: Readonly<
  {
    description: string;
    children: ReadonlyArray<string>;
    icon: Component;
    label: string;
    route: string;
  }[]
> = [
  {
    description: "userNavigation.dashboard.description",
    children: [],
    icon: IconDashboard,
    label: "userNavigation.dashboard.title",
    route: "/dashboard",
  },
  {
    description: "userNavigation.membership.description",
    children: [],
    icon: IconMembership,
    label: "userNavigation.membership.title",
    route: "/dashboard/subscription",
  },
  {
    description: "userNavigation.settings.description",
    children: ["/personal", "/security", "/payment"],
    icon: IconSettings,
    label: "userNavigation.settings.title",
    route: "/dashboard/settings",
  },
  {
    description: "userNavigation.help.description",
    children: [],
    icon: IconHelp,
    label: "userNavigation.help.title",
    route: "/dashboard/help",
  },
] as const;
