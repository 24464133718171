<template>
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 6V0H18V6H10ZM0 10V0H8V10H0ZM10 18V8H18V18H10ZM0 18V12H8V18H0ZM2 8H6V2H2V8ZM12 16H16V10H12V16ZM12 4H16V2H12V4ZM2 16H6V14H2V16Z"
      fill="url(#icon-gradient)"
    />
    <defs>
      <linearGradient
        id="icon-gradient"
        gradientUnits="userSpaceOnUse"
        x1="0"
        x2="19.4952"
        y1="0"
        y2="1.79927"
      >
        <stop stop-color="#F9D416" />
        <stop offset="0.536458" stop-color="#F08379" />
        <stop offset="1" stop-color="#9B4765" />
      </linearGradient>
    </defs>
  </svg>
</template>
